import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { Stack, type StackProps, Typography } from "@mui/joy";
import { memo } from "react";
import EmptyStateBig from "./assets/empty-state-big.svg";
import EmptyStateSmall from "./assets/empty-state-small.svg";

interface EmptyDataProps {
  icon?: IconProp;
  title: string;
  defaulIconSize?: "small" | "big";
  description?: string;
  slotProps?: {
    containerProps?: StackProps;
    iconProps?: FontAwesomeIconProps;
  };
}

const EmptyData = memo<React.PropsWithChildren<EmptyDataProps>>(
  ({ icon, defaulIconSize, title, description, slotProps, children }) => (
    <Stack
      alignItems="center"
      height="100%"
      justifyContent="center"
      width="100%"
      {...slotProps?.containerProps}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon} />
      ) : defaulIconSize === "small" ? (
        <EmptyStateSmall />
      ) : (
        <EmptyStateBig style={{ height: "170px", width: "170px" }} />
      )}
      {title || description ? (
        <Stack
          alignItems="center"
          gap={0}
          justifyContent="center"
          sx={{ maxWidth: "500px" }}
          textAlign="center"
        >
          {title && <Typography level="body-sm">{title}</Typography>}
          {description && (
            <Typography level="body-sm">{description}</Typography>
          )}
        </Stack>
      ) : null}
      {children}
    </Stack>
  )
);

export default EmptyData;
