import { DqLoader } from "@decentriq/components";
import { Alert, Button, Stack } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { EmptyData } from "components";
import { MediaDataRoomInsightsTabs } from "features/MediaInsightsDcr/components";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr/contexts";

const MediaDataRoomInsights: React.FC = () => {
  const { isDeactivated, isPublisher } = usePublishedMediaInsightsDcr();
  const {
    hasRequiredData,
    datasetsLoading,
    error: fetchingError,
    isPublisherAudienceBeingUpdated,
    insights: {
      retry,
      loading: insightsLoading,
      error: insightsFetchingError,
      status: insightsFetchingStatus,
    },
    overlapStatistics: { computeResults: { isEmpty: isEmptyStatistics } = {} },
  } = useMediaInsightsDcrData();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [isDeactivated, retry]);
  if (isDeactivated || insightsFetchingError || fetchingError) {
    return (
      <EmptyData title="Insights could not be retrieved.">
        {!isDeactivated && (
          <Button
            color="neutral"
            loading={retryInProgress}
            onClick={retryComputations}
            variant="soft"
          >
            Retry
          </Button>
        )}
      </EmptyData>
    );
  }
  if (datasetsLoading) {
    return (
      <Stack alignItems="center" height="100%" justifyContent="center">
        <DqLoader label="Connecting to the enclave..." />
      </Stack>
    );
  }
  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyData
        description={
          isPublisher
            ? "Please provision a datalab again."
            : "Activation will be available again soon."
        }
        title="Publisher audience is being updated."
      />
    );
  }
  if (!hasRequiredData) {
    return <Alert>Waiting for both parties to provision data.</Alert>;
  }
  if (insightsLoading) {
    return (
      <Stack alignItems="center" height="100%" justifyContent="center">
        <DqLoader
          label={
            insightsFetchingStatus === "COMPUTING"
              ? "Computing insights..."
              : insightsFetchingStatus === "FETCHING"
                ? "Retrieving insights..."
                : ""
          }
        />
      </Stack>
    );
  }
  if (isEmptyStatistics) {
    return (
      <EmptyData
        description="Please check if the Matching ID is compatible."
        title="There are not enough individuals in the overlap."
      />
    );
  }
  return <MediaDataRoomInsightsTabs />;
};

MediaDataRoomInsights.displayName = "MediaDataRoomInsights";

export default memo(MediaDataRoomInsights);
