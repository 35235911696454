import { DqLoader } from "@decentriq/components";
import {
  ComputeJobAutoFetching,
  ComputeJobStatus,
} from "@decentriq/graphql/dist/types";
import { faPlay, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Tooltip,
} from "@mui/joy";
import { Allotment } from "allotment";
import { memo } from "react";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
} from "contexts";
import {
  ComputeNodeEditor,
  ComputeNodeNameEditor,
  ComputeNodeResult,
  ComputeNodeType,
  useComputeNode,
} from "features/computeNode/components";
import {
  useComputeNodeRun,
  useDevComputeNodeRun,
  useTestDraftComputation,
} from "features/computeNode/components/ComputeNodeActions/hooks";
import { useDevComputeNode } from "features/devComputeNodes/contexts/devComputeNode";
import {
  type ComputeNodeTypeNames,
  workerTypeShortNameToDraftComputeNodeTypeName,
  workerTypeShortNameToPublishedComputeNodeTypeName,
} from "models";

interface ComputeNodeEditorDialogProps {
  computeNodeId: string;
}

const ComputeNodeEditorDialog: React.FC<ComputeNodeEditorDialogProps> = memo(
  ({ computeNodeId }) => {
    const { isEditorDialogOpened, closeEditorDialog, executionContext } =
      useComputeNodesVars();
    const { playgroundId } = useDevComputeNode();
    const { allowTestMode, isPublished } = useDataRoom();
    const { testing } = usePublishedDataRoom();
    const isTestingEnabled = isPublished ? testing : allowTestMode;
    const node = useComputeNode(computeNodeId);
    const computationType: ComputeNodeTypeNames | undefined =
      node && node.computationType
        ? executionContext === "development"
          ? workerTypeShortNameToDraftComputeNodeTypeName.get(
              node.computationType
            )
          : workerTypeShortNameToPublishedComputeNodeTypeName.get(
              node.computationType
            )
        : undefined;
    const hasResultToFetch =
      node?.job?.status === ComputeJobStatus.Succeded &&
      node?.job?.autoFetching !== ComputeJobAutoFetching.None;
    const { loading: computeNodeRunLoading, runComputeNode } =
      useComputeNodeRun({
        computeNodeId,
      });
    const { loading: computeNodeDraftRunLoading, testDraftComputeNode } =
      useTestDraftComputation({ computationType, computeNodeId });
    const { loading: devComputeNodeRunLoading, runDevComputeNode } =
      useDevComputeNodeRun({ computationType, computeNodeId, playgroundId });
    const isComputationRunning = isPublished
      ? executionContext === "development"
        ? devComputeNodeRunLoading
        : computeNodeRunLoading
      : computeNodeDraftRunLoading;
    return (
      <Modal
        onClose={closeEditorDialog}
        open={isEditorDialogOpened(computeNodeId)}
      >
        <ModalDialog layout="fullscreen">
          <DialogTitle
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                gap: "1rem",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <ComputeNodeNameEditor computeNodeId={computeNodeId} />
              <ComputeNodeType computeNodeId={computeNodeId} />
            </Box>
            <Box>
              {((allowTestMode && isTestingEnabled) ||
                executionContext === "development") && (
                <Tooltip title={isTestingEnabled ? "Run test" : "Run"}>
                  <IconButton
                    color={isTestingEnabled ? "secondary" : "primary"}
                    disabled={isComputationRunning}
                    // When we prefix these with void, the loading doesn't start for the button, and the computation doesn't run

                    onClick={
                      isPublished
                        ? executionContext === "development"
                          ? runDevComputeNode
                          : runComputeNode
                        : testDraftComputeNode
                    }
                    variant="soft"
                  >
                    {isComputationRunning ? (
                      <DqLoader />
                    ) : (
                      <FontAwesomeIcon fixedWidth={true} icon={faPlay} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              <IconButton onClick={closeEditorDialog}>
                <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent
            sx={{
              "--focus-border": "var(--joy-palette-primary-500)",
              "--separator-border": "var(--joy-palette-divider)",
              marginBlockEnd: "calc(var(--Card-padding) * -2)",
              padding: "0 !important",
            }}
          >
            <Allotment vertical={true}>
              <Allotment.Pane>
                <ComputeNodeEditor
                  computeNodeId={computeNodeId}
                  editorOptions={{ fullHeight: true, resizable: true }}
                  readOnly={false}
                />
              </Allotment.Pane>
              <Allotment.Pane preferredSize="25%">
                {/* TODO in stabilization -> https://www.notion.so/decentriq/Wording-for-Computation-Result-area-0ac4e2950375414ea75bf0956270e452?pvs=4 */}
                {/* <Typography>Run test to see the result here</Typography> */}
                {hasResultToFetch && (
                  // The allotment package recommend this to have scrollbar in the pane
                  <Stack
                    sx={{
                      height: "100%",
                      overflow: "auto",
                      pt: 2,
                      width: "100%",
                    }}
                  >
                    <ComputeNodeResult
                      autoFetching={node?.job?.autoFetching!}
                      computationTypename={node?.computationType}
                      computeNodeId={computeNodeId}
                      dcrHash={node?.job?.dataRoomHash}
                      driverAttestationHash={node?.job?.driverAttestationHash}
                      jobId={node?.job?.id!}
                    />
                  </Stack>
                )}
              </Allotment.Pane>
            </Allotment>
          </DialogContent>
        </ModalDialog>
      </Modal>
    );
  }
);
ComputeNodeEditorDialog.displayName = "ComputeNodeEditorDialog";

export default ComputeNodeEditorDialog;
