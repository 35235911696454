// TODO: Fix joy migration
import {
  ImageCropper,
  useImageCropper,
  type UseImageCropperOptions,
} from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import { faArrowUpFromLine } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useBoolean } from "ahooks";
import { Fragment, memo, useCallback, useEffect } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar } from "hooks";

interface ImageUploadButtonProps {
  TriggerButtonProps?: { children?: React.ReactNode };
  SelectButtonProps?: { children?: React.ReactNode };
  modalTitle?: string;
  imageCropperHookOptions?: UseImageCropperOptions;
  uploadButtonTitle?: string;
  disabled?: boolean;
  snackbarOrigin?: string;
  skipCropping?: boolean;
  onImageSelected?: (base64Data: string) => void;
  testIdPrefix?: string;
}

const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({
  TriggerButtonProps = { children: "Upload new logo" },
  SelectButtonProps = { children: "Upload new logo" },
  modalTitle = "New logo upload",
  disabled,
  snackbarOrigin = CommonSnackbarOrigin.ADMIN,
  imageCropperHookOptions = {
    fillMode: "transparent",
    maxSizeInMbs: 10,
    minImageDimensions: { height: 100, width: 100 },
  },
  skipCropping,
  onImageSelected,
  testIdPrefix = "",
}) => {
  const [cropperOpen, { setTrue: openCropper, setFalse: closeCropper }] =
    useBoolean(false);
  const [isCropping, { setTrue: setIsCropping, setFalse: dismissCropping }] =
    useBoolean(false);
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: snackbarOrigin,
  });
  const handleError = useCallback(
    (error: Error) =>
      enqueueSnackbar(error.message, { persist: true, variant: "error" }),
    [enqueueSnackbar]
  );
  const handleImageSelection = useCallback(
    ({ base64Data }: { base64Data: string }) => {
      if (skipCropping) {
        onImageSelected?.(base64Data);
        return;
      }

      setIsCropping();
    },
    [skipCropping, setIsCropping, onImageSelected]
  );
  const { selectImage, cropImage, ImageCropperProps, resetImageSelection } =
    useImageCropper({
      onCroppingComplete: onImageSelected,
      onError: handleError,
      onImageSelectionComplete: handleImageSelection,
      options: imageCropperHookOptions,
      testIdPrefix: testIdPrefix,
    });
  useEffect(() => {
    if (!cropperOpen) {
      dismissCropping();
      resetImageSelection();
    }
  }, [cropperOpen, dismissCropping, resetImageSelection]);
  return (
    <>
      <Button
        disabled={disabled}
        onClick={openCropper}
        startDecorator={<FontAwesomeIcon icon={faArrowUpFromLine} />}
        sx={{ height: "fit-content" }}
        {...TriggerButtonProps}
        data-testid={
          testIdPrefix + testIds.components.imageUploader.triggerButton
        }
      />
      <Modal onClose={closeCropper} open={cropperOpen}>
        <ModalDialog>
          <DialogTitle>{modalTitle}</DialogTitle>
          <Divider />
          <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
            {isCropping ? (
              <Box alignItems="center" display="flex" flexDirection="column">
                <Typography level="body-sm" sx={{ mb: 2 }} textAlign="center">
                  Choose the part of the image to be used.
                  <br />
                  Area not covered by your image will be filled transparently.
                </Typography>
                <ImageCropper {...ImageCropperProps} />
              </Box>
            ) : (
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={selectImage}
                  startDecorator={<FontAwesomeIcon icon={faArrowUpFromLine} />}
                  sx={{
                    height: "fit-content",
                    mb: 7,
                    mt: 5,
                  }}
                  {...SelectButtonProps}
                  data-testid={
                    testIdPrefix +
                    testIds.components.imageUploader.openExplorerButton
                  }
                />
                <Typography
                  level="body-sm"
                  sx={{ color: "var(--joy-palette-neutral-500)" }}
                >
                  File should be at least{" "}
                  {imageCropperHookOptions.minImageDimensions?.width}x
                  {imageCropperHookOptions.minImageDimensions?.height} in .png
                  or .jpg format
                </Typography>
              </Box>
            )}
          </DialogContent>
          {isCropping && (
            <Fragment>
              <Divider />
              <DialogActions>
                <Button
                  color="primary"
                  data-testid={
                    testIdPrefix + testIds.components.imageUploader.submitButton
                  }
                  onClick={() => {
                    closeCropper();
                    cropImage();
                  }}
                  variant="solid"
                >
                  Save
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </ModalDialog>
      </Modal>
    </>
  );
};

ImageUploadButton.displayName = "ImageUploadButton";

export default memo(ImageUploadButton);
