import { InfoTooltip } from "@decentriq/components";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Option,
  Select,
  styled,
  Typography,
} from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { PlainTextEditorField } from "components";
import { type ReportErrorInput, useReportError } from "hooks";
import { logError } from "utils";

export enum ErrorReportPriority {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

interface ErrorReportDialogProps {
  open: boolean;
  generateReport: (details: string) => Promise<ReportErrorInput>;
  onCancel: () => void;
  onFinish: () => void;
  defaultDetails: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const StyledBox = styled(Box)(({ theme }) => ({
  border: "1px solid #E0DFDA",
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5, 1),
}));

const StyledTextarea = styled("textarea")({
  border: "none",
  color: "inherit",
  fontFamily: "inherit",
  resize: "none",
  width: "100%",
});

const ErrorReportDialog: React.FC<ErrorReportDialogProps> = ({
  open,
  onCancel,
  onFinish,
  generateReport,
  defaultDetails,
  header,
  footer,
}) => {
  const reportError = useReportError();
  const [sending, setSending] = useState(false);
  const [priority, setPriority] = useState<ErrorReportPriority>(
    ErrorReportPriority.Medium
  );
  const [moreDetails, setMoreDetails] = useState<string>("");
  const baseDetails = `Priority: ${priority}\n${defaultDetails}`;
  const details = moreDetails.trim()
    ? `${moreDetails}\n-------------------------\n${baseDetails}`
    : baseDetails;
  const handleReportSending = useCallback(async () => {
    try {
      setSending(true);
      const report = await generateReport(details || "");
      await reportError(report);
      onFinish();
    } catch (error) {
      logError(error);
    } finally {
      setSending(false);
    }
  }, [details, reportError, onFinish, generateReport]);
  return (
    <Modal
      onClose={onCancel}
      open={open}
      sx={{
        "--joy-zIndex-modal": 1450, // TODO: Close the snackbar before opening this dialog and remove this override
      }}
    >
      <ModalDialog>
        <DialogTitle>Report issue to Decentriq</DialogTitle>
        <Divider />
        <DialogContent>
          <FormControl>
            <FormLabel sx={{ display: "flex" }}>
              Priority{" "}
              <InfoTooltip
                tooltip={
                  <p style={{ margin: 0 }}>
                    High - this issue is blocking your current workflow.
                    <br />
                    Medium - this issue is happening, but it's still possible to
                    continue with your workflow.
                    <br />
                    Low - this is happened but does not impact your current
                    workflow.
                  </p>
                }
              />
            </FormLabel>
            <Select
              onChange={(event, value) => {
                setPriority(value as ErrorReportPriority);
              }}
              sx={{ minWidth: "200px" }}
              value={priority}
            >
              {Object.entries(ErrorReportPriority).map(([key, value]) => (
                <Option key={key} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </FormControl>
          {header}
          <StyledBox>
            <Typography level="body-sm">
              <StyledTextarea
                onChange={(event) => setMoreDetails(event.target.value)}
                placeholder="More details about the error and context (optional)"
                rows={3}
                value={moreDetails}
              />
            </Typography>
          </StyledBox>
          <StyledBox>
            <Typography
              level="body-md"
              sx={{ marginBottom: (theme) => theme.spacing(1) }}
            >
              Error message (this is the full message that will be sent to
              Decentriq)
            </Typography>
            <PlainTextEditorField
              editorOptions={{
                dynamicHeight: false,
                height: 325,
                readOnly: true,
                resizable: true,
                wordWrap: "on",
              }}
              value={details}
            />
          </StyledBox>
          {footer}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            loading={sending}
            loadingPosition="start"
            onClick={handleReportSending}
            startDecorator={<FontAwesomeIcon icon={faPaperPlane} />}
            variant="solid"
          >
            Submit
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

ErrorReportDialog.displayName = "ErrorReportDialog";

export default memo(ErrorReportDialog);
