import { DqCard, DqCardContent } from "@decentriq/components";
import {
  faDatabase,
  faFlask,
  faNewspaper,
  faTable,
  type IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { NavLink } from "react-router-dom";

interface OtherPageLink {
  title: string;
  icon: IconDefinition;
  link: string;
}

const PAGES_LINKS: OtherPageLink[] = [
  {
    icon: faTable,
    link: "/datasets/datasets",
    title: "Datasets",
  },
  {
    icon: faNewspaper,
    link: "/publisherportal",
    title: "Publisher portal",
  },
  {
    icon: faDatabase,
    link: "/data-partner-portal",
    title: "Data partner portal",
  },
  {
    icon: faFlask,
    link: "/publisherportal/datalabs",
    title: "Datalabs",
  },
];

const OtherPagesBlock = memo(() => (
  <Stack height="100%">
    <Typography fontWeight={500} level="body-md">
      Other pages
    </Typography>
    <DqCard sx={{ flex: 1 }}>
      <DqCardContent
        sx={{ alignItems: "stretch", justifyContent: "flex-start" }}
      >
        <Stack>
          {PAGES_LINKS.map(({ title, link, icon }) => (
            <Button
              color="primary"
              component={NavLink}
              key={link}
              startDecorator={<FontAwesomeIcon fixedWidth={true} icon={icon} />}
              sx={{ justifyContent: "flex-start" }}
              to={link}
              variant="plain"
            >
              {title}
            </Button>
          ))}
        </Stack>
      </DqCardContent>
    </DqCard>
  </Stack>
));

OtherPagesBlock.displayName = "OtherPagesBlock";

export default OtherPagesBlock;
